export const useAuth = () => {
    const router = useRouter();
    const config = useRuntimeConfig();
    const api = useApi();
    const user = useUser();

    const isAuthenticated = computed(() => user.value !== null);

    async function fetchUser(): Promise<any> {
        user.value = await api.v1.auth.user();
    }

    async function login(
        email: string,
        password: string,
        remember = true
    ): Promise<any> {
        if (isAuthenticated.value === true) {
            return;
        }
        await api.v1.auth.login({ email, password, remember });
        await fetchUser();

        await router.push(config.public.appUrl);
    }

    async function register(
        email: string,
        password: string,
        password_confirmation: string,
    ): Promise<any> {
        await api.v1.auth.register({
            email,
            password,
            password_confirmation,
        });
        await fetchUser();

        await router.push(config.public.appUrl);
    }

    async function logout(): Promise<any> {
        if (isAuthenticated.value === false) {
            return;
        }

        await api.v1.auth.logout();
        user.value = null;

        await router.push(config.public.homeUrl);
    }

    async function forgotPassword(email: string): Promise<any> {
        await api.v1.auth.forgotPassword(email);
        user.value = null;

        await router.push(config.public.forgotUrl);
    }

    async function resetPassword(token: string,
        email: string,
        password: string,
        password_confirmation: string): Promise<any> {
        await api.v1.auth.resetPassword(token, email, password, password_confirmation);
        user.value = null;

        await router.push(config.public.loginUrl);
    }

    async function verifyEmail(id: string, hash: string): Promise<any> {
        await api.v1.auth.verifyEmail(id, hash);
    }

    return {
        user,
        fetchUser,
        isAuthenticated,
        login,
        register,
        logout,
        forgotPassword,
        resetPassword,
        verifyEmail,
    };
};